* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  outline: 0;
  box-shadow: none;
  text-decoration: none;
}
html,
body {
  position: relative;
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', sans-serif;
}

body {
  min-height: 100vh;
  font-family: 'Segoe UI', sans-serif;
  background: #fff url('/images/bg-texture-2.jpg') no-repeat center;
  background-size: cover;
  background-attachment: fixed;
}

a,
p,
input,
select,
textarea,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Segoe UI', sans-serif;
}

.loader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 3000;
  background: #fff url('/images/bg-texture-2.jpg') no-repeat center;
  background-size: cover;
  background-attachment: fixed;
  transition: all 2s;
  margin: 0 auto;
  border-radius: 0;
  &.inactive {
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
  }
  .loader-inner {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    height: 100%;
    width: 100%;
    img {
      max-width: 100px;
      max-height: 100px;
    }
  }
}

header,
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
  flex-wrap: nowrap;
  padding: 0 $gutter;
  z-index: 1000;
  @include mobile() {
    background: #fff url('/images/bg-texture-2.jpg') no-repeat center;
  }
  .logoHolder {
    padding: 16px;
    display: block;
    width: auto;
    padding-top: 35px;
    @include mobile() {
      padding-top: 16px;
      padding-left: 0;
    }
    a,
    img {
      display: block;
      max-width: 90px;
      max-height: 90px;
      @include mobile() {
        max-width: 75px;
        max-height: 75px;
      }
    }
  }
  .menu-button {
    display: none;
    width: 36px;
    height: 36px;
    cursor: pointer;
    border: none;
    outline: none;
    box-shadow: none;
    background: none;
    .menu-icon {
      display: none;
      width: 36px;
      height: 36px;
      overflow: visible;
      svg {
        width: 36px;
        height: 36px;
        overflow: visible;
      }
    }
    @include mobile() {
      display: inline-block;
      .menu-icon {
        display: block;
        &.light {
          display: none;
        }
      }
    }
  }
  .menuHolder {
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: auto;
    .menu-footer {
      display: none;
      position: absolute;
      top: auto;
      bottom: 0;
      width: 100%;
      .socialIcons {
        background: $army;
      }
    }
    @include mobile() {
      position: fixed;
      margin-left: auto;
      background: $army;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      transition: all 0.3s;
      padding: 0;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      &.menu-active {
        visibility: visible;
        z-index: 1000;
        opacity: 1;
      }
      .menu-footer {
        display: block;
      }
    }
    ul,
    li {
      list-style: none;
      display: inline-block;
      padding-left: $gutter;
      padding-right: $gutter;
      @include mobile() {
        display: block;
        text-align: center;
        width: 100%;
        padding: 0;
      }
      li {
        @include mobile() {
          padding: 15px;
          // border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        }
      }
      a {
        &,
        &:link,
        &:visited,
        &:hover,
        &:focus {
          color: #fff;
          font-size: 1rem;
          @include mobile() {
            font-size: 1.1rem;
          }
        }
      }
    }
    &.menu2 {
      ul,
      li {
        a {
          &,
          &:link,
          &:visited,
          &:hover,
          &:focus {
            color: $army;
            @include mobile() {
              color: #fff;
            }
          }
        }
      }
    }
    .menu-link {
      &:nth-child(1) {
        animation-delay: 1s;
      }
      &:nth-child(2) {
        animation-delay: 1.1s;
      }
      &:nth-child(3) {
        animation-delay: 1.2s;
      }
      &:nth-child(4) {
        animation-delay: 1.3s;
      }
      &:nth-child(5) {
        animation-delay: 1.4s;
      }
    }
  }
}
footer,
.footer {
  position: fixed;
  top: auto;
  left: auto;
  right: 0;
  bottom: 0;
  width: 60%;
  height: auto;
  z-index: 100;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include mobile() {
    width: 100%;
    position: relative;
    background: $putty;
    display: block;
  }
  .socialIcons,
  .franchise-link {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    text-align: center;
  }
  .socialIcons {
    justify-content: space-evenly;
    padding: 10px 0;
    align-items: center;
    width: 100%;
  }
  .social-link-icons {
    text-align: center;
    display: block;
    width: 33.3333%;
    position: relative;
    line-height: 1;
    &:after {
      content: '';
      width: 2px;
      opacity: 0.4;
      background: #fff;
      position: absolute;
      height: 80%;
      top: 10%;
      left: auto;
      right: 0;
      display: block;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
    svg {
      path {
        fill: #fff;
        transition: all 0.3s;
      }
    }
    &:hover {
      svg {
        path {
          fill: $putty;
        }
      }
    }
  }
  .franchise-link {
    background: $frachieseBG;
    text-align: center;
    padding: $gutter;
    justify-content: center;
    position: relative;
    width: auto;
    padding-left: 50px;
    padding-right: 50px;
    a {
      font-size: 1.4rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      display: flex;
      align-items: center;
      vertical-align: middle;
      text-align: center;
      flex-wrap: nowrap;
      white-space: nowrap;
      z-index: 10;
      transition: color 0.3s;
      @include largeDesktop() {
        font-size: 1.2rem;
      }
      @include mediumDesktop() {
        font-size: 1.1rem;
      }
      @include smallDesktop() {
        font-size: 1rem;
      }
      img {
        margin-top: 10px;
        margin-right: $gutter;
      }
      svg {
        margin-top: 7px;
        margin-right: 20px;
        path {
          fill: $army;
          transition: all 0.3s;
        }
      }
      &,
      &:link,
      &:visited,
      &:hover,
      &:active {
        color: $army;
      }
    }
    &::before,
    &::after {
      content: '';
      width: 0%;
      height: 100%;
      position: absolute;
      z-index: 0;
      background: $putty;
      transition: width 0.5s;
      transition-delay: 0s;
      left: 0;
      top: 0;
      right: auto;
    }
    &::after {
      left: auto;
      right: 0;
      background: $army;
      transition-delay: 0s;
    }
    &:hover {
      &::before {
        width: 100%;
      }
      &::after {
        width: 100%;
        z-index: 1;
        transition-delay: 0.4s;
      }
      a {
        &,
        &:link,
        &:visited,
        &:hover,
        &:active {
          color: #fff;
          transition-delay: 0.6s;
          svg {
            path {
              fill: #fff;
              transition-delay: 0.7s;
            }
          }
        }
      }
    }
  }
}

.lightMobileMenu {
  @include mobile() {
    .header {
      background: none;
      .menu-button {
        display: inline-block;
        .menu-icon {
          display: block;
          &.dark {
            display: none;
          }
          &.light {
            display: block;
          }
        }
      }
    }
  }
}

.text-uppercase{
  text-transform: uppercase !important;
}