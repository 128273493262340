@font-face {
  font-family: 'Segoe UI';
  src: local('Segoe UI Italic'), local('SegoeUI-Italic'),
    url('../fonts/SegoeUI-Italic.woff2') format('woff2'),
    url('../fonts/SegoeUI-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Segoe UI';
  src: local('Segoe UI'), local('SegoeUI'),
    url('../fonts/SegoeUI.woff2') format('woff2'),
    url('../fonts/SegoeUI.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: local('Segoe UI Bold Italic'), local('SegoeUI-BoldItalic'),
    url('../fonts/SegoeUI-BoldItalic.woff2') format('woff2'),
    url('../fonts/SegoeUI-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Segoe UI';
  src: local('Segoe UI Bold'), local('SegoeUI-Bold'),
    url('../fonts/SegoeUI-Bold.woff2') format('woff2'),
    url('../fonts/SegoeUI-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
