@mixin mobile() {
  @media screen and (max-width: 767px) {
    @content;
  }
}

@mixin tablet() {
  @media screen and (min-width: 767px) and (max-width: 1024px) {
    @content;
  }
}

@mixin mobileSmall() {
  @media screen and (max-width: 320px) {
    @content;
  }
}

@mixin smallDesktop() {
  @media screen and (max-width: 1140px) {
    @content;
  }
}

@mixin mediumDesktop() {
  @media screen and (max-width: 1280px) {
    @content;
  }
}

@mixin largeDesktop() {
  @media screen and (max-width: 1440px) {
    @content;
  }
}

@mixin extraLargeDesktop() {
  @media screen and (min-width: 2000px) {
    @content;
  }
}
